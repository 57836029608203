import axios from './backend.config';

export default class StepService {
  
  /*** API name: getStep */
  static getStep = async (): Promise<any> => {
    try {
      return await axios.get(`/steps`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    }
    catch (error) {
      throw(error)
    }
  }

  /*** API name: getPalletStep */
  static getPalletStep = async (ref: string): Promise<any> => {
    try {
      return await axios.get(`/pallets/${ref}/steps`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    }
    catch (error) {
      throw(error)
    }
  }

  /*** API name: getProductStep */
  static getProductStep = async (ref: string): Promise<any> => {
    try {
      return await axios.get(`/products/${ref}/steps`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    }
    catch (error) {
      throw(error)
    }
  }

  /*** API name: getOrderStep */
  static getOrderStep = async (ref: string): Promise<any> => {
    try {
      return await axios.get(`/orders/${ref}/steps`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    }
    catch (error) {
      throw(error)
    }
  }

}