import React from 'react';
import GoogleMapReact from 'google-map-react';
import '../assets/styles/maker.css';

const APIKEY: string = process.env.REACT_APP_API_KEY_GMAPS || "";

interface IMapProps {
  center: any,
  zoom: any
  text?: string
  width?: string,
  height: string,
}
interface IMark {
  lat: number,
  lng: number,
  text?: string
}

const AnyReactComponent = (data: IMark) => <div className="pin bounce">{data.text}</div>;

export default (props: IMapProps, {
  width = "100%"
}) => {
  
  const center = {lat:props.center.latitude, lng: props.center.longitude};

  return (
    <div style={{ width: props.width, height: props.height }}>
      <GoogleMapReact
            bootstrapURLKeys={{ key: APIKEY }}
            center={center}
            defaultZoom={props.zoom}
          >
            <AnyReactComponent
              lat={props.center.latitude}
              lng={props.center.longitude}
              text={props.text}
            />
      </GoogleMapReact>
    </div>

  );
}