import React from "react";
import Navigation from "../components/Navbar";
import pegatina from "../assets/images/pegatina-peq.png";
const TypeSelect: React.FC = (props: any) => {


  const dispatch = (event: any) => {
    switch (event.target.id) {
      case "1":
        props.history.push("/listProduct");
        break;
      case "2":
        props.history.push("/load");
        break;
      case "3":
        props.history.push("/paths");
        break;
      case "4":
        props.history.push("/order");
        break;
    }
  };

  return (
    <section id="options">
        <Navigation>Elige una opción</Navigation>
        <div className="sideTop">
          <div id="pegatina-peq">
            <img className="imgover" src={pegatina} alt="" />
          </div>
      </div>
      <div className="sideTwo justify-content-center align-items-center">
        <div className="buttons-grid">
          <button
            id="1"
            type="submit"
            className="btn btn-gold btn-block"
            onClick={(event) => dispatch(event)}
          >
              RECEPCIÓN
            </button>
          <button
            id="2"
            type="submit"
            className="btn btn-gold btn-block"
            onClick={(event) => dispatch(event)}
          >
            CARGA
            </button>
          <button
            id="3"
            type="submit"
            className="btn btn-gold btn-block"
            onClick={(event) => dispatch(event)}
          >
            SALIDA
            </button>
          <button
            id="4"
            type="submit"
            className="btn btn-gold btn-block"
            onClick={(event) => dispatch(event)}
          >
            ENTREGA
            </button>
        </div>
      </div>
    </section>
  );
};

export default TypeSelect;
