import React, { useState, useContext } from "react";
import InputForm from "../components/InputForm";
import { IProduct, ICoordinate, STATUS } from "../api/backend/dto";
import ProductService from "../api/backend/product.service";
import NavBar from "../components/Navbar";
import ModalError from "../components/Modals/ModalError";
import { UserContext } from "../context/UserContext";

const ListPallet: React.FC = (props: any) => {
  const { setGeolocation, getGeolocation } = useContext(UserContext);

  const productsInit: IProduct[] = [];
  const [productsSelected, setProductsSelected] = useState(productsInit);
  //Hook to strore show boolean of modal
  const [show, setShow] = useState<boolean>(false);
  //Hook to strore the error message
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [pathName, setPathName] = useState("");

  const deleteElement = (i: string) => {
    setProductsSelected(productsSelected.filter((el) => el.reference !== i));
  };

  const getProductName = async (id: string) => {
    const product = await ProductService.getProduct(id);
    if (product.id !== 0) {
      if (productsSelected.filter(p => p.reference === product.reference).length === 0) {
        setProductsSelected([...productsSelected, product])
      }
    } else {
      setShow(true);
      setErrorMessage("Producto no encontrado");
    }
  };

  const onClickPath = async (event: any) => {
    setGeolocation();

    const coords: ICoordinate = {
      latitude: getGeolocation.latitude,
      longitude: getGeolocation.longitude,
    }; 
    console.log(coords);

    const data = {
      path_id: pathName,
      latitude: coords.latitude,
      longitude: coords.longitude,
      products: productsSelected.map((p) => p.reference),
    };
    let formData = new FormData();
    formData.append("step_info", JSON.stringify(data));

    ProductService.masiveUpdateProductStatus(STATUS.LOADED, formData).then(
      (res) => {
        console.log(res);
        if (res.status === 200) {
          props.history.push({
            pathname: "/confirm",
            title: "Registro carga",
            confirmMessage: "Carga confirmada",
            buttonLocation: "/load",
            buttonMessage: "Nueva Carga",
          });
        } else {
        }
      }
    )
    .catch(err => {
      setShow(true)
      setErrorMessage(err.apiError.message)
    });

  }


  return (
    <section id="load" className="wrapper">
      <NavBar>Crear Ruta</NavBar>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="d-flex">
              <h3>
                <span className="icon info"></span>
              </h3>
              Escanea los productos que forman parte de esta ruta.
            </div>
            <form>
              <div className="form-group" style={{ marginBottom: "0.25rem" }}>
                <input
                  name="path"
                  className="form-control"
                  placeholder="Identificación de la ruta"
                  onChange={(e) => setPathName(e.target.value)}
                />
              </div>
            </form>
            <ul className="list-group list-group-flush">
              {productsSelected.map((ele) => (
                <li key={ele.reference} className="card">
                  <div className="left d-flex">
                    <div id="card-content">
                      <h4>{ele.name}</h4>
                      <h5>Ref. {ele.reference}</h5>
                    </div>
                    <button
                      id={ele.reference}
                      type="submit"
                      className="btn"
                      onClick={(event) => {
                        deleteElement(event.currentTarget.id);
                      }}
                    >
                      <span className="icon delete w3-display-right"></span>
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="row centered">
          <InputForm onSubmit={(text: any) => getProductName(text)} />
        </div>
      </div>
      <footer className="footer">
        <button
          type="button"
          onClick={onClickPath}
          className="btn btn-red btn-block"
        >
          ACEPTAR
        </button>
      </footer>
      <ModalError
        show={show}
        message={errorMessage}
        onHide={() => setShow(false)}
      ></ModalError>
    </section>
  );
};

export default ListPallet;
