import React from 'react';
import { Modal } from 'react-bootstrap'
import { IPath2, IProduct } from '../../api/backend/dto';

interface ModalProps {
    show: boolean,
    onHide: (e:any)=> void,
    path?: IPath2,
    products?: IProduct[]
}

const ModalProducts = (props: ModalProps) => {
    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title className="ModalOrderH">Ruta {props.path?.id}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="ModalOrderB">
            {props.products?.sort((a, b) => {
              if (a.reference < b.reference) return -1;
              if (a.reference > b.reference) return 1;
              return 0;
            }).map((p) => (
                <li key={p.reference} className="listmodal">
                <div className="left d-flex">
                  <div id="card-content">
                    <h5><strong>
                      {p.name}</strong>
                    </h5>
                    <h5>
                      Ref. {p.reference}
                    </h5>
                  </div>
                </div>
                </li>
              ))
              }
            </Modal.Body>
        </Modal >
    );
};

export default ModalProducts
