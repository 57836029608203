import axios from "./backend.config";
import { IProduct, IProductsFilter, STATUS } from "./dto";
import {ErrorHandling}  from '../../components/ErrorHandling';

export default class ProductService {
  static getProduct = async (ref: string): Promise<any> => {
    let product: IProduct = {
      id: 0,
      created_at: "",
      updated_at: "",
      reference: "",
      name: "",
    };

    try {
      const response = await axios.get(`/products/` + ref, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return product;
    }
  };

  /*** API name: createProduct */
  static createProduct = async (ref: string, name: string): Promise<any> => {
    var data = JSON.stringify({ reference: ref, name: name });

    try {
      return await axios.post(`/products`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error.request.status);
    }
  };

  /*** API name: updateProductStatus */
  static updateProductStatus = async (
    ref: string,
    status: STATUS,
    formData: FormData
  ): Promise<any> => {
    try {
      let response = { data: "" };

      if (status === STATUS.RECEIVED) {
        response = await axios.put(
          `/products/${ref}?status=${status}`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static masiveUpdateProductStatus = async (
    status: STATUS,
    formData: FormData
  ): Promise<any> => {
    try {
      let response = { data: "" };

      response = await axios.put(`/products?status=${status}`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      //console.log(response);
      return response;
    } catch (error) {
      console.log(error.request.status)
      console.log(error.request.response);
      //throw error;
      throw ErrorHandling.onApiError(error.request.response, error.request.status);
    }
  };

  static linkToPath = async (ref: string, pathID: number): Promise<any> => {
    try {
      return await axios.put(`/products/${ref}/path/${pathID}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    } catch (error) {
      throw error;
    }
  };

  static linkToOrder = async (ref: string, orderID: number): Promise<any> => {
    try {
      return await axios.put(`/products/${ref}/order/${orderID}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    } catch (error) {
      throw error;
    }
  };

  static getProductDetails = async (ref: string): Promise<any> => {
    try {
      return await axios.get(`/products/${ref}/details`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    } catch (error) {
      throw error;
    }
  };

  static getAllProducts = async (filter: IProductsFilter): Promise<any> => {

    let sumfilters = {}
      if(filter.status)
        Object.assign(sumfilters, {"status": filter.status});
      if(filter.pallet_id)
        Object.assign(sumfilters, {"pallet_id": filter.pallet_id});
      if(filter.order_id)
        Object.assign(sumfilters, {"order_id": filter.order_id});

    try {
      return await axios.get(`/products`, {
        params: sumfilters,
      });
    } catch (error) {
      throw error;
    }
  };
}
