import React, { useState, useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { IStep, ICoordinate, STATUS, IProductsFilter, IProduct } from "../api/backend/dto";

import MapContainer from "../components/MapContainer";
import receptionIcon from "../assets/images/reception-icon.png";

import MapService from "../api/backend/map.service";
import ProductService from "../api/backend/product.service";
import StepService from "../api/backend/step.service";

import chargeIcon from "../assets/images/charge-icon.png";
import departureIcon from "../assets/images/departure-icon.png";
import deliveryIcon from "../assets/images/delivery-icon.png";

import moment from "moment";
import "moment/locale/es";

export interface IProps {
  searchText: string;
  steps: any;
}

interface ILine {
  title: string;
  children?: any;
}

const formatDate = (date: string) => {
  if(date === "") return ""
  const dateLocal = moment(date);
  return dateLocal.format("LLL");
};

const ZoomMap = 17;
const noResult = "Este paso aún no ha sido realizado.";
const URL_ETH = process.env.REACT_APP_API_BLOCKCHAIN_URL;
const product_INI : IProduct = { created_at: "",  id: 0, name: "", reference: "", updated_at: ""}
const step_INI : IStep = {
  created_at: "", type: "", item_id: "", item_type: "", name: "", pallet_id: "", tx_id: "", latitude:0, longitude:0 
}

const toCenter = (step: IStep) => {
  const coords: ICoordinate = {
    latitude: step.latitude,
    longitude: step.longitude,
  };
  return coords;
};


const AddressLocation = (props: any) => {
  const [address, setAdress] = useState("");
  
  if (props.center.latitude !== 0 && props.center.latitude !== 0) {
    MapService.getLocation(props.center)
      .then((res) => {
        setAdress(res.results[0].formatted_address);
      })
      .catch((err) => console.log(err));
  }
  return <>{address}</>;
};

const Line = (props: ILine) => {
  return (
    <li>
      <span>
        <strong>{props.title}:</strong> {props.children}
      </span>
    </li>
  );
};

export const Pallet = (props: any) => {
  const reference = props.searchText;
  const step : IStep = props.steps[0];
  const [numProducts, setNumProducts] = useState(0);
  
  const filter: IProductsFilter = {
    pallet_id : reference
  };

  ProductService.getAllProducts(filter).then((res) => {
    //console.log(res.data.products);
    setNumProducts(res.data.products.length)
  })

  return (
    <Row>
      <Col>
        <h4>
          <strong>Palet: {reference}</strong>
        </h4>
        <Card>
          <Card.Title>
            <img src={receptionIcon} alt="" />
            <h4>1. Recepción</h4>
          </Card.Title>
          <Card.Body>
          {step ?                 
              ( 
                <>
            
              <div>
                <Line title="Fecha">
                  {formatDate(step.created_at)}
                </Line>

                <Line title="Lugar">
                  <AddressLocation center={toCenter(step)} />
                </Line>
              
              <div className="mapPedido">
                <MapContainer
                  center={toCenter(step)}
                  zoom={ZoomMap}
                  text={reference}
                  height={"180px"}
                ></MapContainer>
              </div>
            </div>
            <div className="rightCard">
            <Line title="Nº Producto">
              {numProducts}
            </Line>
            <Line title="ID blockchain">{step.tx_id}</Line>
            <button className="btn btn-gray btn-block">
            <a href={URL_ETH + step.tx_id} target="_blank" rel="noopener noreferrer"> VER CERTIFICADO</a>
            </button>

            </div>
          
            </>
              ) : ( 
              <div>{noResult}</div>
              )
              }
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export const Order = (props: any) => {
  const reference = props.searchText;
  const order : IStep = props.steps[0];
  const [numProducts, setNumProducts] = useState(0);
  const [stepSent, setStepSent] = useState(step_INI);
  const [prodFirst, setProdFirst] = useState(product_INI);  
  const filter: IProductsFilter = {
    order_id : reference
  };

  useEffect(() => {
    ProductService.getAllProducts(filter).then((res) => {
      props.productsCallback(res.data);
      setProdFirst(res.data.products[0]); 
      setNumProducts(res.data.products.length);
    })
  }, [])

  useEffect(() => {
    if(prodFirst.reference !== "")
    {
      StepService.getProductStep(prodFirst.reference).then((resProducts) => {
        const resStepSent = resProducts.data.steps.filter((item: IStep) => item.type === STATUS.SENT)[0];
        setStepSent(resStepSent)        
      })
    }
  },[prodFirst]);
  

  return (
    <Row>
      <div id="pedidoContent">
        <Col>
          <h4>Pedido: {reference}</h4>

          <Card>
            <Card.Title>
              <h4>
                <img src={deliveryIcon} alt="" /> 1. Ruta del pedido
              </h4>
            </Card.Title>
            <Card.Body>
              <div id="leftCardPedido">
                <Line title="Fecha salida">
                  {formatDate(stepSent.created_at)}
                </Line>

                <Line title="Lugar salida">
                  <AddressLocation center={toCenter(stepSent)} />
                </Line>

                <div className="mapPedido">
                  <MapContainer
                    center={toCenter(stepSent)}
                    zoom={ZoomMap}
                    text={reference}
                    height={"150px"}
                    width={"380px"}
                  ></MapContainer>
                </div>
                <Line title="Ruta">
                  {prodFirst.path_id}
                  </Line>
                <Line title="Nº de productos">
                  {numProducts}
                </Line>
                <Line title="ID blockchain">{stepSent.tx_id}</Line>
                <button className="btn btn-gray btn-block">
                  <a href={URL_ETH + stepSent.tx_id} target="_blank" rel="noopener noreferrer"> VER CERTIFICADO</a>
                </button>
              </div>
              <div id="rightCardPedido">
                <Line title="Fecha entrega">
                  {formatDate(order.created_at || "")}
                </Line>

                <Line title="Lugar entrega">
                  <AddressLocation center={toCenter(order)} />
                </Line>

                <div className="mapPedido">
                  <MapContainer
                    center={toCenter(order)}
                    zoom={ZoomMap}
                    text={reference}
                    height={"150px"}
                    width={"380px"}
                  ></MapContainer>
                </div>
                <Line title="ID blockchain">{order.tx_id}</Line>
                <button className="btn btn-gray btn-block">
                  <a href={URL_ETH + order.tx_id} target="_blank" rel="noopener noreferrer"> VER CERTIFICADO</a>
                </button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </Row>
  );
};


 export const Product = (props: any) => {

  const reference = props.searchText;
  const stepReceived : IStep = props.steps.filter((item: IStep) => item.type === STATUS.RECEIVED)[0];
  const stepLoaded : IStep = props.steps.filter((item: IStep) => item.type === STATUS.LOADED)[0];
  const stepSent : IStep = props.steps.filter((item: IStep) => item.type === STATUS.SENT)[0];
  const stepDelivered : IStep = props.steps.filter((item: IStep) => item.type === STATUS.DELIVERED)[0];
  const [productName, setProductName] = useState("");
  const [palletID, setPalletID] = useState("");
  const [pathID, setPathID] = useState("");
  const [orderID, setOrderID] = useState("");
  const [expiration, setExpiration] = useState("");

  ProductService.getProduct(reference).then((res) => {
    //console.log(res);
    setProductName(res.name);
    setPalletID(res.pallet_id);
    setPathID(res.path_id);
    setOrderID(res.order_id);
    setExpiration(res.expiration);
  })


  return (
    <div id="contentShowProducts">
      <h4 id="h4showProducts">
        <span>Producto:</span> <strong>{productName}</strong><br />
        <span>Fecha de consumo preferente:</span> <strong>{expiration}</strong>
      </h4>
      <Row>
        <Col>
          <Card>
            <Card.Title>
              <img src={receptionIcon} alt="" />
              <h4>1. Recepción</h4>
            </Card.Title>
            <Card.Body>
              {stepReceived ?                 
              ( 
                <>
                  <Line title="Fecha">
                    {formatDate(stepReceived.created_at)}
                  </Line>

                  <Line title="Lugar">
                    <AddressLocation center={toCenter(stepReceived)} />
                  </Line>

                  <div className="middleCard">
                    <div className="leftMiddleCard">
                      <Line title="Código pale">
                        {palletID}
                      </Line>
                      <button className="btn btn-gray btn-block smallbutton">
                        <a href={URL_ETH + stepReceived.tx_id} target="_blank" rel="noopener noreferrer"> VER CERTIFICADO</a>
                      </button>
                    </div>
                    <div className="contentMap">
                      <MapContainer
                        center={toCenter(stepReceived)}
                        zoom={ZoomMap}
                        text={reference}
                        height={"100px"}
                      ></MapContainer>
                    </div>
                  </div>
                  <Line title="ID blockchain">{stepReceived.tx_id}</Line>
                </>
              ) : ( 
              <div>{noResult}</div>
              )
              }

            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Title>
              <img src={chargeIcon} alt="" />
              <h4>2. Carga</h4>
            </Card.Title>
            <Card.Body>

            {stepLoaded ?                 
              ( 
                <>

              <Line title="Fecha">
                {formatDate(stepLoaded.created_at)}
              </Line>

              <Line title="Lugar">
                <AddressLocation center={toCenter(stepLoaded)} />
              </Line>

              <div className="middleCard">
                <div className="leftMiddleCard">
                  <Line title="Ruta">
                    {pathID}
                  </Line>
                  <button className="btn btn-gray btn-block smallbutton">
                    <a href={URL_ETH + stepLoaded.tx_id} target="_blank" rel="noopener noreferrer"> VER CERTIFICADO</a>
                  </button>
                </div>
                <div className="contentMap">
                  <MapContainer
                    center={toCenter(stepLoaded)}
                    zoom={ZoomMap}
                    text={reference}
                    height={"100px"}
                  ></MapContainer>
                </div>
              </div>
              <Line title="ID blockchain">{stepLoaded.tx_id}</Line>
                
              </>
              ) : ( 
              <div>{"Producto recepcionado en almacén, pendiente de carga para pedido."}</div>
              )
              }
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Title>
              <img src={departureIcon} alt="" />
              <h4>3. Salida</h4>
            </Card.Title>
            <Card.Body>

            {stepSent ?                 
              ( 
                <>
              <Line title="Fecha">
                {formatDate(stepSent.created_at || "")}
              </Line>

              <Line title="Lugar">
                <AddressLocation center={toCenter(stepSent)} />
              </Line>

              <div className="middleCard">
                <div className="leftMiddleCard">
                  <Line title="Ruta">
                    {pathID}
                  </Line>
                  <button className="btn btn-gray btn-block smallbutton">
                    <a href={URL_ETH + stepSent.tx_id} target="_blank" rel="noopener noreferrer"> VER CERTIFICADO</a>
                  </button>
                </div>
                <div className="contentMap">
                  <MapContainer
                    center={toCenter(stepSent)}
                    zoom={ZoomMap}
                    text={reference}
                    height={"100px"}
                  ></MapContainer>
                </div>
              </div>
              <Line title="ID blockchain">{stepSent.tx_id}</Line>

              </>
              ) : ( 
              <div>{"Producto en pedido de venta cargado en ruta, pendiente expedición."}</div>
              )
              }
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Title>
              <img src={deliveryIcon} alt="" />
              <h4>4. Entrega</h4>
            </Card.Title>
            <Card.Body>
            
              {stepDelivered ?                 
              ( 
                <>
              <Line title="Fecha">
                {formatDate(stepDelivered.created_at || "")}
              </Line>

              <Line title="Lugar">
                <AddressLocation center={toCenter(stepDelivered)} />
              </Line>

              <div className="middleCard">
                <div className="leftMiddleCard">
                  <Line title="Producto">{productName}</Line>
                    {/* <Line title="Código Producto">
                      {reference}
                    </Line> */}
                  <Line title="Código Pedido">
                    {orderID}
                  </Line>
                  <button className="btn btn-gray btn-block smallbutton">
                    <a href={URL_ETH + stepDelivered.tx_id} target="_blank" rel="noopener noreferrer"> VER CERTIFICADO</a>
                  </button>
                </div>
                <div className="contentMap">
                  <MapContainer
                    center={toCenter(stepDelivered)}
                    zoom={ZoomMap}
                    text={reference}
                    height={"100px"}
                  ></MapContainer>
                </div>
              </div>
              <Line title="ID blockchain">{stepDelivered.tx_id}</Line>

              </>
              ) : ( 
              <div>{"Producto en ruta de envío en curso, pendiente de entrega al cliente."}</div>
              )
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};