import React, { useState, useEffect, useContext } from "react";
import {
  IProductsFilter,
  STATUS,
  IProduct,
  IPath2,
  ICoordinate,
} from "../api/backend/dto/index";
import ProductService from "../api/backend/product.service";
import ModalProducts from "../components/Modals/ModalProductos";
import NavBar from "../components/Navbar";
import { UserContext } from "../context/UserContext";

const Paths: React.FC = (props: any) => {
  const { setGeolocation, getGeolocation } = useContext(UserContext);

  //Confirm function to save the selecteds paths on database
  const confirm = async (event: any) => {
    setGeolocation();

    const coords: ICoordinate = {
      latitude: getGeolocation.latitude,
      longitude: getGeolocation.longitude,
    };

    pathsSelected.forEach((path: string) => {
      const data = {
        path_id: path,
        latitude: coords.latitude,
        longitude: coords.longitude,
        products: paths.filter(p => p.id === path).map((p) => p.products.map(p => p.reference)).flat(),
      };
      let formData = new FormData();
      formData.append("step_info", JSON.stringify(data));

      ProductService.masiveUpdateProductStatus(STATUS.SENT, formData).then(
        (res) => {
          console.log(res);
          if (res.status === 200) {
            props.history.push({
              pathname: "/confirm",
              title: "Registro salida",
              confirmMessage: "Salida de ruta registrada",
              buttonLocation: "/TypeSelect",
              buttonMessage: "Inicio",
            });
          } else {
          }
        }
      );
    });
  };

  //Path hooks to store the paths from the database on load
  const [paths, setPaths] = useState<IPath2[]>([]);
  //Modal hook to show the modal
  const [showModal, setShowModal] = useState<boolean>(false);
  //Paths hooks tho store the selected paths
  const [pathsSelected, setPathsSelected] = useState<string[]>([]);
  //Products hooks to store the produts to show in the modal detail
  const [pathDetailModal, setPathDetailModal] = useState<IPath2>();

  //Function to store in the paths hook the selecteds ones
  const selectedPath = async (event: any) => {
    event.currentTarget.checked
      ? setPathsSelected([event.currentTarget.name, ...pathsSelected])
      : setPathsSelected(
          pathsSelected.filter((x) => x !== event.currentTarget.name)
        );
  };

  //Products to show in the modal detail
  const pathModal = (event: any) => {
    setPathDetailModal(
      paths.find((path) => path.id === event.currentTarget.name)
    );
    setShowModal(true);
  };

  //UseEffect hook to get all the paths on page load
  useEffect(() => {
    const filter: IProductsFilter = {
      status: STATUS.LOADED,
    };
    ProductService.getAllProducts(filter).then((res) => {
      const products: IProduct[] = res.data.products;

      const paths = products.map((p) => p.path_id);
      const pathsFiltered = paths
        .filter((path, index) => paths.indexOf(path) === index)
        .map((path) => {
          let pathobj: IPath2 = {
            id: path!,
            products: products.filter(p => p.path_id === path)
          };
          return pathobj;
        })
      setPaths(pathsFiltered);
    });
  }, []);

  return (
    <section id="paths" className="wrapper">
      <NavBar>Salida de Camión</NavBar>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="d-flex">
              <h3>
                <span className="icon info"></span>
              </h3>
              Selecciona a continuación las rutas que van a salir.
            </div>
            <ul className="list-group list-group-flush">
              {paths.map((ele) => (
                <li key={ele.id} className="card">
                  <div className="left d-flex">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name={ele.id.toString()}
                        className="custom-control-input w3-display-left"
                        id={"P-" + ele.id.toString()}
                        onClick={(event) => selectedPath(event)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"P-" + ele.id.toString()}
                      ></label>
                    </div>

                    <div id="card-content">
                      <h4>
                        <label
                          className="custom-toggle-color"
                          htmlFor={"P-" + ele.id.toString()}
                        >
                          Ruta: {ele.id}
                        </label>
                      </h4>
                      <h5>{ele.products.length} productos</h5>
                    </div>
                    <button
                      type="submit"
                      name={ele.id.toString()}
                      className="btn"
                      onClick={(event) => pathModal(event)}
                    >
                      <span className="icon plus w3-display-right"></span>
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <footer className="footer">
        <button
          type="submit"
          className="btn btn-red btn-block"
          onClick={(event) => confirm(event)}
        >
          CONFIRMAR SALIDA
        </button>
      </footer>
      <ModalProducts
        show={showModal}
        onHide={() => setShowModal(false)}
        path={pathDetailModal}
        products={pathDetailModal?.products}
      ></ModalProducts>
    </section>
  );
};

export default Paths;
