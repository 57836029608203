/** ENUMS */
export enum STATUS {
    RECEIVED = "received",
    LOADED = "loaded",
    SENT = "sent",
    DELIVERED = "delivered",
  }

/** User Interface **/
export interface IUser {
    user_id: string,
    user_password: string
}

export interface ICoordinate {
    latitude: number,
    longitude: number
}

export interface IReference {
    reference: string
}

export interface IPallet extends ICoordinate {
    pallet_id: string
}

export interface IStep extends ICoordinate {
    name: string,
    pallet_id: string
    created_at: string,
    type: string,
    item_type: string,
    item_id: string,
    tx_id: string
}

export interface IProduct {
    id: number,
    name: string,
    reference: string,
    created_at: string,
    updated_at: string,
    deleted_at?: string,
    path_id?: string,
    order_id?: number
    pallet_id?: number
    status?: STATUS,
    expiration?: string
}


export interface IPalletResponse {
    reference: string,
    status: string
}

export interface IPallet extends ICoordinate {
    id: number,
    products: IProduct[],
    created_at: string,
    updated_at: string,
    deleted_at?: string,
    reference: string,
    user_id?: number,

}

export interface IPath extends ICoordinate {
    id: number,
    delivered: boolean,
    name: string,
    created_at: string,
    updated_at: string,
    deleted_at?: string,
    delivered_at?: string,
    user_id: number,
    products: IProduct[],
}
export interface IPath2 {
    id: string,
    products: IProduct[]
}

export interface IOrder extends ICoordinate {
    order_id: number,
    order_ref: string,
    order_dni: string,
    created_at?: string,
    UpdatedAt?: string,
    deleted_at?: string,
    delivered_at?: string,
    Delivered: boolean,
    user_id: string,
    products: IProduct[],
}
export interface IOrder2 extends ICoordinate {
    order_id: string,
    receiver_id: string,
    receiver_name: string,
}

export interface IAggregate {
    pallet: IStep,
    path: IStep,
    order: IStep,
    product: IStep,
}

export interface IProductsFilter {
    status?: STATUS,
    order_id?: string,
    pallet_id?: string
}