import React, { useState } from "react";
import InputForm from "../components/InputForm";
import { useForm } from "react-hook-form";
import { IOrder2, IProduct } from "../api/backend/dto";
import ProductService from "../api/backend/product.service";
import ModalOrder from "../components/Modals/ModalOrder";
import NavBar from "../components/Navbar";
import ModalError from "../components/Modals/ModalError";
//import { UserContext } from "../context/UserContext";

const Order: React.FC = (props: any) => {
  //const { userLogged, setGeolocation, getGeolocation } = useContext(UserContext);
  //Initial products to initialize the selected products hook
  const productsInit: IProduct[] = [];
  //Initia order to initializa the order hook
  const orderInit: IOrder2 = {
    order_id: "",
    receiver_id: "",
    receiver_name: "",
    latitude: 0,
    longitude: 0,
  };
  //Products selected hook to store scanned products
  const [productsSelected, setProductsSelected] = useState(productsInit);
  //Modal hook to show the modal
  const [showModal, setShowModal] = useState(false);
  //Order hook where order data will be store
  const [order, setOrder] = useState<IOrder2>(orderInit);

  //Hook to strore show boolean of modal
  const [show, setShow] = useState<boolean>(false);
  //Hook to strore the error message
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [orderRef, setOrderRef] = useState<string>("");

  //Function to delete a product from the hooks of the scanned ones
  const deleteElement = (i: string) => {
    setProductsSelected(productsSelected.filter((el) => el.reference !== i));
  };

  //Function to get the product name by an id scanned
  const getProductName = async (id: string) => {
    const product = await ProductService.getProduct(id);
    if (product.id !== 0) {
      if (productsSelected.filter(p => p.reference === product.reference).length === 0) {
        setProductsSelected([...productsSelected, product])
      }
    } else {
      setShow(true);
      setErrorMessage("Producto no encontrado");
    }
  };

  //Form hook to get the information on submit and pass the data to the modal
  const { register } = useForm();
  const onClickOrder = () => {
    setOrder({
      order_id: orderRef,
      receiver_name: "", // TODO
      receiver_id: "",
      latitude: 0,
      longitude: 0,
    });
    setShowModal(true);
  };

  return (
    <>
      <NavBar>Entrega de pedido</NavBar>
      <section id="order" className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="d-flex">
                <h3>
                  <span className="icon info"></span>
                </h3>
                Introduce el código de pedido y escanéa los productos
                entregados.
              </div>
              <form>
                <div className="form-group" style={{ marginBottom: "0.25rem" }}>
                  <input
                    name="path"
                    className="form-control"
                    placeholder="Código de pedido..."
                    ref={register}
                    onChange={(e) => setOrderRef(e.target.value)}
                  />
                </div>
              </form>
              <ul className="list-group list-group-flush">
                {productsSelected.map((ele) => (
                  <li key={ele.reference} className="card">
                    <div className="left d-flex">
                      <div id="card-content">
                        <h4>{ele.name}</h4>
                        <h5>Ref. {ele.reference}</h5>
                      </div>
                      <button
                        id={ele.reference}
                        type="submit"
                        className="btn"
                        onClick={(event) => {
                          deleteElement(event.currentTarget.id);
                        }}
                      >
                        <span className="icon delete w3-display-right"></span>
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="row centered">
            <InputForm onSubmit={(text: any) => getProductName(text)} />
          </div>
        </div>
        <footer className="footer">
          <button
            type="submit"
            onClick={onClickOrder}
            className="btn btn-gold btn-block"
          >
            Firma
          </button>
        </footer>
        <ModalOrder
          show={showModal}
          order={order}
          history={props.history}
          products={productsSelected}
          onHide={() => setShowModal(false)}
        ></ModalOrder>
        <ModalError
          show={show}
          message={errorMessage}
          onHide={() => setShow(false)}
        ></ModalError>
      </section>
    </>
  );
};

export default Order;
