import moment from "moment";
import "moment/locale/es";
import { ICoordinate, IStep } from "../api/backend/dto";

export default class Utils {

    static toCenter = (step: IStep) => {
        const coords: ICoordinate = {
          latitude: step.latitude,
          longitude: step.longitude,
        };
        return coords;
      };
    
    static formatDate = (date: string) => {
        if(date === "") return ""
        const dateLocal = moment(date).format("DD/MM/YYYY | HH:mm");
        //console.log(dateLocal.format("LLL"))
        return dateLocal.replace("|", "a las").concat("h");
      };

    static formatDateLocale = (date: string) => {
    return (date === "") ? "" :
        (new Date(date).toLocaleDateString())
    }
    
}