import React from 'react';
import { CSVLink } from "react-csv"
import { Modal } from 'react-bootstrap'

interface ModalProps {
  show: boolean,
  onHide: (e: any) => void,
  products: any,
  typeSearch: string
}

const renderTable = (data: any) => {
  if (data.length === 0) {
    return <tr></tr>
  }

  return (
    <>
      {data.map((element: any) => (
        < tr key={element.reference} >
          <th scope="row">{element.name}</th>
          <td>{element.pallettime}</td>
          <td>{element.palletcoordinates}</td>
          <td>{element.loadtime}</td>
          <td>{element.loadcoordinates}</td>
          <td>{element.pathtime}</td>
          <td>{element.pathcoordinates}</td>
          <td>{element.delivertime}</td>
          <td>{element.deivercoordinates}</td>
        </tr >
      ))}
    </>
  )
}

const ModalExport = (props: ModalProps) => {
  

  const headers = [
    { label: "Nombre", key: "name" },
    { label: "Recepción Hora", key: "pallettime" },
    { label: "Recepción coordenadas", key: "palletcoordinates" },
    { label: "Carga Hora", key: "loadtime" },
    { label: "Carga coordenadas", key: "loadcoordinates" },
    { label: "Salida Hora", key: "pathtime" },
    { label: "Salida coordenadas", key: "pathcoordinates" },
    { label: "Entrega Hora", key: "delivertime" },
    { label: "Entrega coordenadas", key: "deivercoordinates" }
  ];

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className="ModalOrderB">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Producto</th>
              <th scope="col">Recepción</th>
              <th scope="col">Recepción</th>
              <th scope="col">Carga</th>
              <th scope="col">Carga</th>
              <th scope="col">Salida</th>
              <th scope="col">Salida</th>
              <th scope="col">Entrega</th>
              <th scope="col">Entrega</th>
            </tr>
          </thead>
          <tbody>
          {renderTable(props.products)}
          </tbody>
        </table>
        <CSVLink
          data={props.products}
          headers={headers}
          filename={"export.csv"}>
          Exportar
        </CSVLink>
      </Modal.Body>
    </Modal >
  );
};

export default ModalExport
