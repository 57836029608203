import React, { useState, useEffect } from 'react'
import { ICoordinate } from '../api/backend/dto';

interface IState {
  userLogged: string,
  setUserLogger: Function,
  setGeolocation: Function,
  getGeolocation: ICoordinate,
  setIsAdmin: Function
}

const initialState = {
  userLogged: '',
  setUserLogger: () => null,
  setGeolocation: () => null,
  getGeolocation: { latitude: 0, longitude: 0 },
  setIsAdmin: () => null
}

export const UserContext = React.createContext<IState>(initialState)

export function UserProvider(props: any): JSX.Element {
  const [userLogged, setUserLogger] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [coordinate, setCoordinate] = useState<ICoordinate>({
    latitude: 0, longitude: 0
  })

  useEffect(() => {
    var isMobile = navigator.userAgent.toLowerCase().match(/mobile/i);
    if (isMobile)
      setIsMobile(true);

  },[isMobile]);

  useEffect(() => {
    const getAdmin = localStorage.getItem("isAdmin")
    if(getAdmin !== null){
      setAdmin(Boolean(getAdmin))
      //setAdmin(localStorage.getItem("isAdmin"))
    }
    if ("geolocation" in navigator){
      //console.log("geolocation in navigator")
      setGeolocation();
      navigator.geolocation.getCurrentPosition((a)=>{}, (b)=>{});
    }
  }, [])

  const setIsAdmin = (admin: boolean) => {
    setAdmin(admin)
    localStorage.setItem("isAdmin", admin.toString())
  }

  const setGeolocation = () => {
    navigator.geolocation.watchPosition(function(position) {
      //console.log("coordenadas: ", position.coords.latitude, position.coords.longitude)
      setCoordinate({ latitude: position.coords.latitude, longitude: position.coords.longitude});
    });
  }

  const state = {
    userLogged: userLogged,
    setUserLogger: setUserLogger,
    setGeolocation: setGeolocation,
    getGeolocation: coordinate,
    setIsAdmin: setIsAdmin
  }

  return <UserContext.Provider value={state}>
    <div id={isMobile ? "viewport-mobile" : admin ? "view-admin" : "viewport-web"}>
      {props.children}
    </div>
  </UserContext.Provider>
}