import axios from './backend.config';
import { IUser } from './dto'


export default class UserService {

    /**
    * API name: getStudents
    */
    static getStudents = async (): Promise<any> => {
        return await axios.get(`/api/students`);
    }

    /**
    * API name: createStudent
    */
    static login = async (data: IUser): Promise<any> => {

      try{
        return await axios.post(`/login`, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
      }
      catch(error){
          console.log(error.request.status)
      }

    }



  }