import React from 'react';
import { Modal, Button } from 'react-bootstrap'

interface ModalProps {
    show: boolean,
    onHide: (e:any)=> void,
    message: string
}

const ModalError = (props: ModalProps) => {
    return (
        <Modal show={props.show}>
            <Modal.Header>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {props.message}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={props.onHide} variant="secondary">Cerrar</Button>
            </Modal.Footer>
        </Modal >
    );
};

export default ModalError
