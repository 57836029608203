import React, { useState } from 'react';

type FormProps = {
    onSubmit: any
}

const useInputValue = (initialValue: any) => {
    const [value, setValue] = useState(initialValue);

    return {
        value,
        onChange: (e: any) => setValue(e.target.value),
        resetValue: () => setValue("")
    };
};

export default ({ onSubmit }: FormProps) => {
    const { resetValue, ...text } = useInputValue("");

    return (
        <form onSubmit={(e: any) => {
            e.preventDefault();
            onSubmit(text.value);
            resetValue();

        }}>
            <input {...text} className="sinbordefondo" />
        </form>
    );
};