import React from "react";
import {
  Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import history from './history'

// Pages
import login from '../views/login';
import listProduct from '../views/listProduct';
import typeSelect from '../views/typeSelect';
import confirm from '../views/confirm';
import administrator from '../views/administrator';
import paths from '../views/paths'
import load from '../views/load'
import order from "../views/order";
import { UserProvider } from "../context/UserContext";



const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Redirect exact from="/" to="login" />
      <UserProvider>
        <Route exact path={'/login'} component={login} ></Route>
        <Route exact path={'/listProduct'} component={listProduct}></Route>
        <Route exact path={'/order'} component={order}></Route>
        <Route exact path={'/typeSelect'} component={typeSelect}></Route>
        <Route exact path={'/confirm'} component={confirm}></Route>
        <Route exact path={'/paths'} component={paths}></Route>
        <Route exact path={'/load'} component={load}></Route>
        <Route exact path={'/administrator'} component={administrator}></Route>
      </UserProvider>
    </Switch>
  </Router>
);

export default AppRouter;