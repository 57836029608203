export class ErrorHandling {

    public static onApiError(message: string, type: number) : object {
        let objError =  {}

        objError =  { "apiError" : {
            "message" : message,
            "type" : type }};

        return objError;
    }

    public static translateMessage(text: string) : string {
        let message =  ""

        switch(text) {
            case "Uknown Error":
                message = "Mensaje para humano"
                break;
        }

        return message;
    }


}