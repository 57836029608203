import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
//import {Navbar, Nav} from 'react-bootstrap'

import $ from "jquery";

import icoHome from "../assets/images/home-menu-icon.png";



const Navigation = (props: any) => {
  const title = props.children;

  useEffect(() => {
    $(document).ready(function () {
      $("[data-toggle=collapse-side]").click(function (e) {
        $(".side-collapse").toggleClass("in");
      });

      $(".menuCloseButton").click(function (e) {
        $(".side-collapse").toggleClass("in");
      });
    });
  }, []);

  return (
    <nav className="navbar fixed-top navbar-icon-top navbar-expand-lg navbar-dark bg-menu">
      <div className="container-title-menu"><span className="title">{title}</span></div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse-side"
        data-target=".side-collapse"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="navbar-default side-collapse in">
        <div className="menuCloseButton">
          <button type="button" className="btn">
            <svg className="icon-close" role="img" viewBox="0 0 24 24">
              <path
                d="M9.82 12L0 2.18 2.18 0 12 9.82 21.82 0 24 2.18 14.18 12 24 21.82 21.82 24 12 14.18 2.18 24 0 21.82z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </div>
        <nav role="navigation" className="navbar-collapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink to="/typeSelect" className="nav-link d-flex">
                <img src={icoHome} alt="" /> <h3>Home</h3>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/listProduct" className="nav-link">
                <h3>1. Recepción</h3>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/load" id="exprimidoM" className="nav-link">
                <h3>2. Carga</h3>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/paths" className="nav-link">
                <h3>3. Salida</h3>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/order" className="nav-link">
                <h3>4. Entrega</h3>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </nav>
  );
};

export default Navigation;