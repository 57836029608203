import React from "react";
import NavBar from "../components/Navbar";

import Ok from "../assets/images/ok.png";

const ConfirmPallet: React.FC = (props: any) => {
  const buttonConfirm = (event: any) => {
    props.history.push(props.location.buttonLocation);
  };

  return (
    <section id="confirm" className="wrapper">
      <NavBar>{props.location.title}</NavBar>
      <div className="container">
        <div className="row">
          <div className="col" style={{textAlign: "center"}}>
            <p style={{textAlign: "center", marginTop: "50px"}}>
              <img style={{width:'6rem'}} src={Ok} alt="" /></p>
            <h1>{props.location.confirmMessage}</h1>
            <br></br>
            <br></br>
            <button
              type="submit"
              className="btn btn-gold btn-block"
              onClick={(event) => buttonConfirm(event)}
            >
              {props.location.buttonMessage}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmPallet;
