import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { UserContext } from "../context/UserContext";
import Users from "../api/backend/user.service"
import pegatina from "../assets/images/pegatina.png";
import ModalError from "../components/Modals/ModalError";

import { geolocated } from "react-geolocated";

const Login: React.FC = (props: any) => {

  const { setUserLogger, setGeolocation, setIsAdmin } = useContext(UserContext);

  //Hook to strore show boolean of modal
  const [show, setShow] = useState<boolean>(false)
  //Hook to strore the error message
  const [errorMessage, setErrorMessage] = useState<string>('')

  const { register, handleSubmit } = useForm();

  const onSubmit = (data: any) => {

    if(props.isGeolocationAvailable)
      setGeolocation();

    if(!props.isGeolocationEnabled){
      setErrorMessage("permita compartir su geolocalización antes de proceder")
      setShow(true)
    }
    else {

      Users.login(data).then((result) => {

        if (result !== undefined && result.data.token) {
          const isAdmin = result.data.is_admin
          setIsAdmin(isAdmin);
          if(isAdmin)
            props.history.push("/administrator");
          else
            props.history.push("/typeSelect");

          setUserLogger(data.email)
          localStorage.setItem("token", result.data.token);
          console.log(result.data.token)
        } else {
          setErrorMessage("Usuario o contraseña incorrecto")
          setShow(true)
        }
      })
    }

  };

  return (
    <section id="login">
      <div className="sideOne">
        <div id="pegatina">
          <img className="imgover" src={pegatina} alt="" />
        </div>
      </div>
      <div className="sideTwo loginform justify-content-center align-items-center">
        <div className="card card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <input
                name="email"
                type="text"
                className="form-control"
                placeholder="Email"
                ref={register}
              />
            </div>
            <div className="form-group">
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder="Contraseña"
                ref={register}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-gold btn-block"
              >
                ENTRAR
                </button>
            </div>
          </form>
        </div>
      </div>
      <ModalError show={show} message={errorMessage} onHide={() => setShow(false)}></ModalError>
    </section>
  );
};

export default geolocated({
  positionOptions: {
      enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Login);
