import { ICoordinate } from './dto'
import axios from 'axios';

export default class MapService {

    /**
    * API name: getLocation
    */
    static getLocation = async (coords: ICoordinate): Promise<any> => {
       const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=${process.env.REACT_APP_API_KEY_GMAPS}`;
       return await axios.get(url);
    }
  }