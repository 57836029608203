import React, { useState, useContext } from "react";
import InputForm from "../components/InputForm";
import { IPalletResponse, ICoordinate, IProduct, STATUS } from "../api/backend/dto";
import ProductService from "../api/backend/product.service";
import NavBar from "../components/Navbar";
import ModalError from "../components/Modals/ModalError";
import { UserContext } from "../context/UserContext";


const ListProduct: React.FC = (props: any) => {

  const { setGeolocation, getGeolocation } = useContext(UserContext);

  /* const palletInit: IPallet = {
    id: 0,
    created_at: '',
    updated_at: '',
    reference: '',
    user_id: 0,
    products: [],
    latitude: 0, longitude: 0
  } */
  const palletInit: IPalletResponse = {
    reference: '',
    status: ''
  }
  const [pallet, setPallet] = useState<IPalletResponse>(palletInit)

  //Products array to inicialite the produtcs selected hook
  const productsInit: IProduct[] = [];
  //Hook to strore products scanned by the user
  const [productsSelected, setProductsSelected] = useState(productsInit);

  //Hook to strore show boolean of modal
  const [show, setShow] = useState<boolean>(false)
  //Hook to strore the error message
  const [errorMessage, setErrorMessage] = useState<string>('')
  //Hook to store the current title
  const [title, setTitle] = useState<string>('Escanear palé')
  //Hook to store the current title
  const [description, setDescription] = useState<string>('Escanea el siguiente palé con la pistola para registrarlo.')

  //Confirm function to save the products scanned on the database
  const confirm = async (event: any) => {

    setGeolocation();

    /* pallet.products = productsSelected;*/
    const coords : ICoordinate = {
      latitude : getGeolocation.latitude,
      longitude: getGeolocation.longitude
    } 

    console.log(coords);

    //Update the pallet coordinates
    /* await PalletsService.updatePalletStatus(pallet.reference, STATUS.RECEIVED, coords)
    console.log("status"); */

    /* await PalletService.updatePathCoordinates(pallet.reference,
      { latitude: getGeolocation.latitude, longitude: getGeolocation.longitude }) */

    //Update the pallet references in products
    const data = { pallet_id: pallet.reference, latitude: coords.latitude, longitude: coords.longitude, products: productsSelected.map((p) => p.reference)}
    let formData = new FormData();
    formData.append('step_info', JSON.stringify(data));
     
    ProductService.masiveUpdateProductStatus(STATUS.RECEIVED, formData).then(res => {
      if (res.status === 200) {
          props.history.push({
            pathname: "/confirm",
            title: "Registro recepción",
            confirmMessage: "Palé completado y registrado",
            buttonLocation: "/listProduct",
            buttonMessage: "AÑADIR PALÉ",
          });
      }
    })
    .catch(err => {
      setShow(true)
      setErrorMessage(err.apiError.message)
    });
    // if (res.data.response === "ok") {
    //   setPallet(palletInit);
    //   setProductsSelected(productsInit);
    // }

  };

  //Function to delete a product from the hooks of the scanned ones
  const deleteElement = (i: string) => {
    if (i === pallet.reference) {
      setPallet(palletInit);
      setProductsSelected(productsInit);
    } else {
      setProductsSelected(productsSelected.filter(el => el.reference !== i));
    }
  };

  //Function to get the product name by an id scanned
  const getProductName = async (id: string) => {
    if (pallet.reference === '') {

      //const pallet = await PalletsService.getPallet(id);
      const pallet = { "reference": id, "status": STATUS.RECEIVED }
      console.log(pallet)
      if (pallet.reference !== "") {
        setPallet(pallet)
        setTitle('Escanear productos');
        setDescription('Escanea el siguiente producto con la pistola para asociarlo con al palé.');
      } else {
        setShow(true)
        setErrorMessage('Palé no encontrado')
      }
    }
     else {
      const product = await ProductService.getProduct(id);
      console.log(product);
      if (product.reference !== "") {
        if (productsSelected.filter(p => p.reference === product.reference).length === 0) {
          setProductsSelected([...productsSelected, product])
        }
      } else {
        setShow(true)
        setErrorMessage('Producto no encontrado')
      }
    }
  }


  //Conditional render of pallet Card dependes on scanned pallet
  const renderPallet = () => {
    if (pallet.reference === "") {
      return <div></div>;
    } else {
      return (
        <div className="card">
          <div className="left d-flex">
            <div id="card-content">
              <h4>
                Pallet
              </h4>
              <h5>
                Ref. {pallet.reference}
              </h5>
            </div>
            <button
              id={pallet.reference}
              type="submit"
              className="btn"
              onClick={(event) => {
                deleteElement(event.currentTarget.id);
              }}
            >
              <span className="icon delete w3-display-right"></span>
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <NavBar>{title}</NavBar>
      <section id="listProduct" className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="d-flex">
                <h3>
                  <span className="icon info"></span>
                </h3>
                {description}
              </div>
              {renderPallet()}
              <ul className="list-group list-group-flush">
                {productsSelected.map((ele) => (
                  <li key={ele.reference} className="card">
                    <div className="left d-flex ">
                      <div id="card-content">
                        <h4>
                          {ele.name}
                        </h4>
                        <h5>
                          Ref. {ele.reference}
                        </h5>
                      </div>
                      <button
                        id={ele.reference}
                        type="submit"
                        className="btn"
                        onClick={(event) => {
                          deleteElement(event.currentTarget.id);
                        }}
                      >
                        <span className="icon delete w3-display-right"></span>
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row centered">
            <InputForm onSubmit={(text: any) => getProductName(text)} />
          </div>
        </div>
        <footer className="footer">
          <button
            type="submit"
            className="btn btn-red btn-block"
            onClick={(event) => confirm(event)}
          >
            ACEPTAR
          </button>
        </footer>
        <ModalError show={show} message={errorMessage} onHide={() => setShow(false)}></ModalError>
      </section>
    </>
  );
};

export default ListProduct;