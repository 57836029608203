import React, { useRef, useContext } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button, Form } from "react-bootstrap";
import { IOrder2, IProduct, STATUS, ICoordinate } from "../../api/backend/dto";
import SignatureCanvas from "react-signature-canvas";
import ProductService from "../../api/backend/product.service";
import { UserContext } from "../../context/UserContext";

interface ModalOrder {
  show: boolean;
  onHide: (e: any) => void;
  order: IOrder2;
  history: any;
  products: IProduct[];
}

const ModalOrder = (props: ModalOrder) => {
   const { setGeolocation, getGeolocation } = useContext(UserContext);

  const { register, handleSubmit } = useForm();
  let sigCanvas = useRef<SignatureCanvas>(null); // create a ref using react useRef hook

  const onSubmit = (data: any) => {
    props.order.receiver_id = data.dni;
    props.order.receiver_name = data.name
    if (sigCanvas.current !== null) addOrder(props.order);
  };

  const addOrder = (order: IOrder2) => {
    setGeolocation();

    const coords: ICoordinate = {
      latitude: getGeolocation.latitude,
      longitude: getGeolocation.longitude,
    };

    console.log(coords);

    const data = {
      order_id: order.order_id,
      latitude: coords.latitude,
      longitude: coords.longitude,
      products: props.products.map((p) => p.reference),
      receiver_id: order.receiver_id,
      receiver_name: order.receiver_name
    };
    let formData = new FormData();
    sigCanvas.current?.getCanvas().toBlob((blob) => {
      formData.append("sign_file", blob || "", "signature.png");
      formData.append("step_info", JSON.stringify(data));
      createOrder(formData);
    });
  };

  const createOrder = (formData: FormData) => {
    // OrderService.saveOrder(formData).then((res) => {
    //   const orderID = res.data.id;
    //   order.products.forEach(
    //     async (product) =>
    //       await ProductService.linkToOrder(product.reference, orderID)
    //   );
    //   OrderService.deliverOrder(orderID);
      // props.history.push({
      //   pathname: "/confirm",
      //   title: "Registro entrega",
      //   confirmMessage: "PEDIDO ENTREGADO",
      //   buttonLocation: "/TypeSelect",
      //   buttonMessage: "INICIO",
      // });
    // });
    ProductService.masiveUpdateProductStatus(STATUS.DELIVERED, formData).then(
      (res) => {
        if (res.status === 200) {
          props.history.push({
            pathname: "/confirm",
            title: "Registro entrega",
            confirmMessage: "PEDIDO ENTREGADO",
            buttonLocation: "/TypeSelect",
            buttonMessage: "INICIO",
          });
        }
      }
    );
  };

  const clear = (event: any) => {
    if (sigCanvas.current !== null) sigCanvas.current.clear();

    event.preventDefault();
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="ModalOrderH">DNI y Firma:</Modal.Title>
      </Modal.Header>
      <Modal.Body className="ModalOrderB">
        <div className="order-id">
          <strong>Datos del cliente:</strong>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Control
            type="text"
            placeholder="Introduce DNI..."
            name="dni"
            ref={register}
          />
          <br />
          <Form.Control type="text" placeholder="Introduce Email..." name="name" ref={register} />
          <br />
          <Form.Group controlId="signature">
            <Form.Label>
              <strong>Firma:</strong>
            </Form.Label>
            <button name="clear" className="btn" onClick={(e) => clear(e)}>
              <span className="icon refresh w3-display-right"></span>
            </button>
            <SignatureCanvas
              ref={sigCanvas}
              penColor="#313131"
              canvasProps={{
                width: 500,
                height: 200,
                className: "signatureCanvas",
              }}
            />
            {/* <Form.Control as="textarea" rows={3}  name="signature" ref={register}/> */}
          </Form.Group>
          <Button type="submit" className="btn btn-red btn-block">
            GUARDAR
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalOrder;
