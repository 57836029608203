import axios, {
    AxiosRequestConfig,
    AxiosResponse,
    AxiosError
  } from 'axios';


  const BASE_URL = process.env.REACT_APP_API_ESTRELLA_URL;
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      post: {        // can be common or any other method
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  });

  //instance.defaults.headers.post['Content-Type'] = 'multipart/form-data';

   // Interceptors
  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    //config.headers.post['Content-Type'] = 'multipart/form-data';
    //console.log(config);
    config.headers['Authorization'] = `${localStorage.getItem("token")}`;
    return config;
  }, function (error) {
    console.log(error);
    // Do something with request error
    return Promise.reject(error);
  });

  axios.interceptors.response.use((res: AxiosResponse) => {
    return res.data;

  }, (err: AxiosError) => {

    if (err.response && (err.response.status+'').startsWith('5')) {
      console.log("error 5XX")
    }
    if (err.response && (err.response.status+'').startsWith('4')) {
      console.log("error 4XX")
    }
    console.log(err.response);
    return Promise.reject(err);
  })


  export default instance;