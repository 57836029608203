import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { UserContext } from "../context/UserContext";
import StepService from "../api/backend/step.service";
import { Pallet, Product, Order } from "../components/Admin";
import ModalExport from '../components/Modals/ModalExport';
import ModalError from '../components/Modals/ModalError'
import pegatinapeq from "../assets/images/pegatina-peq.png";
import Utils from "../components/Utils";
import { IStep, STATUS  } from "../api/backend/dto";

const Administrator: React.FC = (props: any) => {

  const { setIsAdmin } = useContext(UserContext);

  //Hook to strore show boolean of modal
  const [showError, setShowError] = useState<boolean>(false)
  //Hook to strore the error message
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [productsByOrder, setProductsByOrder] = useState([]);

  //Form hook to get the information on submit and pass the data to the modal
  const { register, handleSubmit } = useForm();
  const onSubmit = (data: any) => {
    switch (data.searchType) {
      case 'Producto':
        StepService.getProductStep(data.searchId).then((res) => {
          updateDataService(data.searchType, data.searchId, res)
        })
        .catch(e =>{
          setErrorMessage('Producto no encontrado')
          setShowError(true)
        });
        break;
      case 'Palet':
        StepService.getPalletStep(data.searchId).then((res) => {
          updateDataService(data.searchType, data.searchId, res)
        })
        .catch(e =>{
          setErrorMessage('Palé no encontrado')
          setShowError(true)
        });
        break;
      case 'Pedido':
        StepService.getOrderStep(data.searchId).then((res) => {
          updateDataService(data.searchType, data.searchId, res)
        }).catch(e =>{
          setErrorMessage('Pedido no encontrado')
          setShowError(true)
        });
        break;
    };
  }

  //Function to update the data from the service
  const updateDataService = (searchType: string, searchText: string, result: any) => {
    setSearchBy(searchType);
    setDataAggregate({ searchText: searchText, steps: result.data.steps });
  }

  /* const getAddress = (props: ICoordinate) => {
    let address = "";
    MapService.getLocation(props)
      .then((res) => {
        address = res.results[0].formatted_address;
      })
      //.catch((err) => console.log(err));
    return address;
  } */

  const callback = (products: any) => {
    // do something with value in parent component, like save to state
    //setProductsByOrder(products)
    let data : any = [];
    products.products.forEach(async (product: any) => {
      StepService.getProductStep(product.reference).then((resProducts) => {
        const resReceived = resProducts.data.steps.filter((item: IStep) => item.type === STATUS.RECEIVED)[0];
          const resLoaded = resProducts.data.steps.filter((item: IStep) => item.type === STATUS.LOADED)[0];
          const resSent = resProducts.data.steps.filter((item: IStep) => item.type === STATUS.SENT)[0];
          const resDelivered = resProducts.data.steps.filter((item: IStep) => item.type === STATUS.DELIVERED)[0];

          const element = {
            reference: product.reference,
            name: product.name,
            pallettime: Utils.formatDate(resReceived.created_at || ""),
            palletcoordinates: `${resReceived.latitude}, ${resReceived.longitude}`, //getAddress(Utils.toCenter(resReceived)),
            loadtime: Utils.formatDate(resLoaded.created_at || ""),
            loadcoordinates: `${resLoaded.latitude}, ${resLoaded.longitude}`, //getAddress(Utils.toCenter(resLoaded)),
            pathtime: Utils.formatDate(resSent.created_at || ""),
            pathcoordinates: `${resSent.latitude}, ${resSent.longitude}`,
            delivertime: Utils.formatDate(resDelivered.created_at || ""),
            deivercoordinates: `${resDelivered.latitude}, ${resDelivered.longitude}`,
          };
          data.push(element);
          
          setProductsByOrder(productsByOrder.concat(data));
        })
      });
}

  //Hook to store the search type
  const [searchBy, setSearchBy] = useState("");

  //Hook to strore show boolean of modal
  const [showModal, setShowModal] = useState<boolean>(false)

  //Hook to strore showExport boolean of modal
  const [showExport, setShowExport] = useState<boolean>(false)

  //Hook to store the history of the search
  const [dataAggregate, setDataAggregate] = useState<any>(null);

  useEffect(() => {
    if(searchBy === 'Pedido')
      setShowExport(true)
    else
      setShowExport(false)
  }, [searchBy]);

  //Conditional render, depends of what term is looking for
  const conditionalRender = () => {
    if (dataAggregate !== null) {
      switch (searchBy) {
        case "Producto":
          return <Product {...dataAggregate} />;
        case "Palet":
          return <Pallet {...dataAggregate} />;
         case "Pedido":
          return <Order {...dataAggregate} productsCallback={callback} />;
        case "":
          return <div></div>;
      }
    } else {
      return <div></div>;
    }
  };

  const logout = () => {
    setIsAdmin(false);
    props.history.push('/login');
  }

  return (
    <section id="administrator" className="wrapper">

      <div className="navbar bg-menu navbar-admin">
        <div className="left d-flex container-title-menu">
          {/*<span className="title">Administrador</span> */}
           <img src={pegatinapeq} alt="" />
           <button onClick={logout} className="btn">
            <span id="spanSalir">SALIR</span><span className="icon logout w3-display-right"></span>
          </button>
        </div>
      </div>
      <Container className="contentAdmin">
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <Col>
                  <span className="f12">Introduce ID</span>
                  <Form.Control ref={register} name="searchId" />
                </Col>
                <Form.Group as={Col} controlId="formGridState">
                  <span className="f12">Selecciona tipo</span>
                  <Form.Control as="select" ref={register} name="searchType">
                    <option>Producto</option>
                    <option>Palet</option>
                    <option>Pedido</option>
                  </Form.Control>
                </Form.Group>
             {/* </Form.Row>
              <Form.Row> */}
                <Col>
                  <Button type="submit"
                    className="btn btn-red btn-block"
                    onClick={() => setDataAggregate(null)}
                  >
                    BUSCAR
                  </Button>
                </Col>
                <Col>
                  { showExport && (
                    <Button onClick={() => setShowModal(true)} className="btn btn-gold btn-block">
                      EXPORTAR
                    </Button>
                  )}
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>{conditionalRender()}</Col>
        </Row>
      </Container>
      <ModalExport
        show={showModal}
        products={productsByOrder}
        typeSearch={searchBy}
        onHide={() => setShowModal(false)}
      ></ModalExport>
        <ModalError show={showError} message={errorMessage} onHide={() => setShowError(false)}></ModalError>
    </section>
  );
}

export default Administrator;
